import 'primevue/resources/themes/saga-blue/theme.css';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import './assets/layout/layout.scss';


import { createApp, reactive } from 'vue';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';

import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';

import Card from 'primevue/card';

import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';

import Column from 'primevue/column';

import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';

import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';

import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';

import Listbox from 'primevue/listbox';

import Message from 'primevue/message';

import Password from 'primevue/password';

import RadioButton from 'primevue/radiobutton';

import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';

import Skeleton from 'primevue/skeleton';

import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import Tooltip from 'primevue/tooltip';


import Axios from 'axios';

import store from './store'

window.appData = {
    services: {},
    $http: Axios,
    env: {
        profileBaseUrl: "https://p.freecard.fr/p/{id}/{key}",
        recaptchaKey: '6LfppoMbAAAAAIm4il0BvylOKL0pd8xiRWyv-Smn',
        staticBaseUrl: "https://static.freecard.fr",
    },
    ui: {
        sidebar: true,
        top: true,
        settings: false,
        layout: 'static',// static or overlay
        leftProfile: false,
    },
    credentials: {
        jwt: undefined,
        user: undefined,
        authd: false,
        requested: false,
        cleanData: () => {
            localStorage.setItem('_jwt_',  undefined);
            localStorage.setItem('user',  undefined);
            document.location.reload();
        }
    },
};

window.appData.$http.defaults.baseURL = 'https://api.freecard.fr';
//window.appData.$http.defaults.headers.common['accept'] = 'application/ld+json';

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);

    next();
});

const app = createApp(App).use(store);
window.appData.$app = app;

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });

app.config.devtools = true;


app.use(PrimeVue, { ripple: false });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);

app.directive('badge', BadgeDirective);

app.component('Badge', Badge);
app.component('Button', Button);
app.component('Card', Card);

app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);

app.component('Column', Column);

app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);

app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);

app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);

app.component('Listbox', Listbox);

app.component('Message', Message);


app.component('Password', Password);

app.component('RadioButton', RadioButton);

app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);

app.component('Skeleton', Skeleton);

app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);

app.component('Toast', Toast);

app.mount('#app');