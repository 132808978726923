import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./pages/404Page.vue'),
    },
    {
        path: '/p/:id/:key',
        name: 'profile',
        component: () => import('./pages/ProfilePage.vue'),
    },
    {
        path: "/:catchAll(.*)",
        component: () => import('./pages/404Page.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
