<template>
	<div :class="containerClass">

		<Toast position="bottom-right" />

		<div class="layout-main" v-bind:class="{'to-top': toTop}">
			<div class="layout-main-inner">
				<router-view />
			</div>
		</div>

		<footer class="b-footer">
			<div class="footer-content p-d-flex p-justify-center p-align-center">
				<img src="/assets/icons/logo-freecard.svg" alt="freecard" />
				<a href="//freecard.fr">FreeCard</a>
			</div>
		</footer>
	</div>
</template>

<script>
	import { load } from 'recaptcha-v3';

	const __locale = {
		dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
		dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
		dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
		monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
		monthNamesShort: ["Jan", "Feb", "Mar", "Avr", "Mai", "Jui","Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
		today: 'Aujourd\'hui',
		firstDayOfWeek: 1,
	};

	export default {
		data(){
			return {
				data: undefined,
				toTop: true,
				loading: true,
				splashScreen: undefined,
				recaptcha: undefined,
			}
		},
		created(){
			this.splashScreen = document.getElementsByClassName("b-loader-fullpage")[0];

			for (const props in __locale) {
				this.$primevue.config.locale[props] = __locale[props];
			}

			window.addEventListener("scroll", () => {
				this.checkToTop();
			});
			this.checkToTop();
		},
		mounted(){
			window.appData.$main = this;
		},
		watch: {
			$route() {
				this.menuActive = false;
				this.$toast.removeAllGroups();
			},
		},
		methods: {
			loadingState(state){
				this.loading = state;
				if(this.splashScreen !== undefined){
					if(!state){
						// disable it
						window.splashAnime();
						this.activateRecaptcha();
					}
				}
			},
			async activateRecaptcha(){
				this.recaptcha = await load(window.appData.env.recaptchaKey);
			},
			async executeRecaptcha(action){
				const token = await this.recaptcha.execute(action);
				return token;
			},
			isDesktop() {
				return window.innerWidth > 1024;
			},
			checkToTop(){
				if(window.scrollY==0){
					this.toTop = true;
				} else {
					this.toTop = false;
				}
			},
		},
		computed: {
			containerClass() {
				return ['layout-wrapper', {
					'to-top': this.toTop,
					'p-input-filled': this.$appState.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}];
			},
			todayYear(){
				let d = new Date();

				return d.getFullYear();
			}
		},
		mixins: [],
		components: {

		}
	}
</script>

<style scoped>

</style>
